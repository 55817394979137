import React, { useEffect, useState } from 'react'
import  "../Css_for_components/Navigationbar.css";
import { ArrowRightAltRounded,  } from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { MailOutline } from '@mui/icons-material';
import { PhoneAndroidOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSelector ,useDispatch} from 'react-redux';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { reset_member, set_member } from '../Store/Action';
import { useNavigate } from 'react-router-dom';
import Password_change_modal from './Password_change_modal';
import { activate_announcements, activate_membership_status, Deactivate_announcument, get_all_announcements } from '../Admin/Functions/admin_functions';
import { logout, subDays } from '../functions/member_functions';
import { check_site_config } from '../Admin/Functions/admin_functions';
import Announcements_Page from '../Pages/Announcements_Page';
import Payment_Modal from './Payment_Modal';


function Navigation_bar() {
    const [account,setAccount]=useState(null)
    const user=JSON.parse(localStorage.getItem("Member_account"));
    const dispatch= useDispatch();
    const navigate= useNavigate();
    const [siteConfig,setData]=useState({

      primary_email:"",
      primary_phonenumber:"",
      
      
  })
  const check_expiration=(end_date,account_id )=>{
    
    if(subDays(end_date)<=0)
      {
        
              activate_membership_status(account_id,"Rejected").then((res)=>{
            //  console.log(res);
                if(res.status=="success" && res.result==1)
                  {
                    localStorage.removeItem("Member_account");
  dispatch(reset_member());
  setAccount(null);
  setTimeout(() => {
    navigate("/Loginpage")
  }, 1000);
                  }
              })

      }
  }
    const [modal_status,setStatus]=useState(false);
    const [anc_modal, setAnnouncement_modal]=useState(false)
    const [Announcement,setAnnouncement]=useState(null);
    const [payment_Modal,set_Payment_Modal]=useState(null);
    const Modal_toggle=()=>
    {
      setStatus(!modal_status);
    }
    const Announcement_toggle=()=>
      {
        setAnnouncement_modal(!anc_modal);
      }
    const Payment_Modal_toggle=()=>{
      set_Payment_Modal(!payment_Modal);
    }


  const options={}
  const [visible,setVisisble]=useState();
  useEffect(()=>{
    check_site_config().then((res)=>{
      if(res.result>0){
        const info= res.configuration[0];
        setData({
         primary_email:info.primary_email,
         primary_phonenumber:info.primary_phonenumber,
       
        })
      }
    }) 
    const header= document.getElementById("topbar")
    const obeserver= new IntersectionObserver((entries)=>{
      const entry= entries[0];
    
      setVisisble(entry.isIntersecting);
    
    },options)
    obeserver.observe(header)
  
    if(user!==null)
        {
         
          dispatch(set_member(user));
          
          setAccount(user)    
        check_expiration(user.membership_enddate,user.account_id);
        }
        else{
            // console.log(user);
        }
        get_all_announcements().then((res)=>{
          res.announcement.map((anc)=>
          {
       
              if(subDays(anc.post_date)<=0 && anc.status=="inactive" && subDays(anc.end_date)>=0)
              {
                    activate_announcements(anc.ac_id);
                    setAnnouncement(anc);
                    setAnnouncement_modal(true);
              }
              else if(subDays(anc.post_date)<=0 && anc.status=="Active" && subDays(anc.end_date)>=0)
              {
                      setAnnouncement(anc);
                      setAnnouncement_modal(true);
              }
              else if(( anc.status=="Active" && subDays(anc.end_date)<0)){
                    Deactivate_announcument(anc.ac_id);
              }
              

          })

       }) 
  },[])
const handel_logout=()=>{
  dispatch(reset_member());
  setAccount(null);
  logout(true);  
}
  return (
    <>
   {  Announcement && <Announcements_Page  Announcement={Announcement} modal_status={anc_modal} Modal_toggle={Announcement_toggle}/>}
      <div id="topbar" className=" d-flex align-items-center ">
    <div className="container d-flex align-items-center d-none d-md-flex justify-content-center justify-content-md-between">
      <div className="contact-info d-flex align-items-center justify-content-around">
       <div className="">
         <i className="bi bi-envelope-fill"></i><a href={siteConfig.primary_email } className='btn text-light p-3'><MailOutline className='text-warning mx-1'/>{siteConfig.primary_email}</a>
       </div>
       <div className=" "> <i className="bi bi-phone-fill phone-icon"></i> <span className='text-warning' ><PhoneAndroidOutlined/> {siteConfig.primary_phonenumber}</span> </div>
      </div>
      <div className="bg-warning  text-light rounded d-none d-md-block">
        <a href="#about" className="btn  ">Get Started <ArrowRightAltRounded/></a>
      </div>
    </div>
  </div>


  <nav className={visible?"navbar shadow    navbar-expand-sm   sticky-top nav_background ":"navbar  shadow  border navbar-expand-sm   sticky-top nav_scrolled"}>
  <div className="container-fluid ">
    <Link className="navbar-brand " to="/">
        <img src='https://eeva-server.ethiopianevaluationassociation.org/EEVA_logo.png' className='nav_bar_logo img-fluid rounded' alt="Logo"/>
        </Link>
        <button className="navbar-toggler bg-warning" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
      <span className="navbar-toggler-icon "></span>
      </button>

      <div className="collapse navbar-collapse justify-content-center " id="collapsibleNavbar">
      <ul className="navbar-nav  w-100  d-flex justify-content-end px-lg-4 ms-lg-5">
        <li className="nav-item mx-lg-3 p-2">
        <Link className= {visible?" nav-link nav_link":" nav-link nav_link nav_scrolled_link"}  to="/">Home</Link>
        </li>
        <li className="nav-item mx-lg-3 p-2">
        <Link className= {visible?" nav-link nav_link":" nav-link nav_link nav_scrolled_link"}  to="/About">About</Link>
        </li>
        <li className="nav-item mx-lg-3 p-2">
        <Link className= {visible?" nav-link nav_link":" nav-link nav_link nav_scrolled_link"}  to="/Blogs">Blogs</Link>
        </li>
        {account && 
        <li className="nav-item mx-lg-3 p-2">
        <Link className= {visible?" nav-link nav_link":" nav-link nav_link nav_scrolled_link"}  to="/Materials">Materials</Link>
        </li>
        }
        <li className="nav-item mx-lg-3 p-2">
        <Link className= {visible?" nav-link nav_link":" nav-link nav_link nav_scrolled_link"}  to="/Contactus">Contact Us</Link>
        </li>
        {account && 
        <Link className=""  >
        <li className="nav-item dropdown mx-lg-3 p-2 ">
        <a className={visible?" nav-link nav_link dropdown-toggle":" dropdown-toggle nav-link nav_link nav_scrolled_link"}   data-bs-toggle="dropdown">settings</a>
        <ul className="dropdown-menu ">
        <Link className='' style={{textDecoration:"none"}} to={`/Profile/${account.username}`}><li className="dropdown-item">Profile</li></Link>
         <Link className="" style={{textDecoration:"none"}} to={`/Transaction/view/${account.username}`}><li className="dropdown-item"> Transaction History</li></Link>
          <li><span className="dropdown-item" onClick={Modal_toggle}>Change Password</span></li>
          <li onClick={handel_logout}><span className="dropdown-item">Logout</span></li>
      
        </ul>
      </li>
      </Link>


        } 
        {
          account ?"":<li className="nav-item mx-lg-3 d-flex  justify-content-center p-2">
          <Link className='btn  btn-success   ' to="/Loginpage">Become a Member <PeopleAltIcon/></Link>
          </li>
        }
        {
          account ?(<li className="nav-item mx-lg-3 d-flex  justify-content-center p-2">
          <Link className='btn  btn-success ' onClick={Payment_Modal_toggle} >Donations <AttachMoneyIcon/></Link>
          </li>):""
        }
      </ul>

    </div>
  </div>
</nav>

        <Password_change_modal modal_status={modal_status} Modal_toggle={Modal_toggle} account={account}/>
        <Payment_Modal modal_status={payment_Modal} Modal_toggle={Payment_Modal_toggle} account={account} payment_reason_data={{reason:"donation",amount:50}} />
    </>
  )
}

export default Navigation_bar